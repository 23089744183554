import StandardModal from '../../shared/modal/variants/StandardModal';
import WizardStepsIndicator from '../../shared/wizard/WizardStepsIndicator';
import { useTranslation } from 'react-i18next';
import { useCreateDocumentWizard } from '../../../contexts/CreateDocumentContext';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import FileService from '../../../services/FileService';
import { Input } from '../../shared/form-control/Input';
import DropdownSelect from '../../shared/form-control/DropdownSelect';
import MultiTextField from '../../shared/form-control/MultiTextField';
import { Item } from '../../shared/form-control/DropdownDefaultComponents';
import { Access } from '../../../models/Access';
import LanguageUtils from '../../../utils/LanguageUtils';
import DocumentService from '../../../services/DocumentService';
import File from '../File';
import { ClientFormUserRole } from '../../../models/ClientFormUserRoles';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import Loader from '../../shared/Loader';
import { EffectiveDateModalContent } from '../../shared/EffectiveDatePicker';
import DueDatePicker from '../../shared/DueDatePicker';
import { ClientFormStatus } from '../../../models/ClientFormStatus';

const DetailStep = () => {
  const { nextStep, prevStep, stepNames, setNewDocument, newDocument, classes } = useCreateDocumentWizard();
  const { t } = useTranslation(['documents', 'organisation']);
  const [version, setVersion] = useState(`${newDocument.majorVersion}.${newDocument.minorVersion}`);
  const [number, setNumber] = useState(`${newDocument.number}`);
  const [isValidating, setIsValidating] = useState(false);
  const [isNumberValid, setIsNumberValid] = useState(true);
  const currentUser = useRecoilValue(currentUserAtom);

  const fromFile = useMemo(() => !!newDocument.fileId, [newDocument.fileId]);

  const deleteFile = useCallback(() => {
    if (newDocument.fileId) {
      FileService.deleteFile(newDocument.fileId).then(() => {
        setNewDocument && setNewDocument((prev) => ({ ...prev, fileId: undefined, file: undefined }));
        prevStep && prevStep();
      });
    }
  }, [newDocument.fileId, prevStep, setNewDocument]);

  const classesOptions = useMemo(() => {
    return classes.map((item) => ({
      id: item.clientModuleId,
      text: LanguageUtils.getTranslation('name', item.templateModule.translations || {}),
      value: item.clientModuleId,
    }));
  }, [classes]);

  const selectedClass = useMemo(
    () => classesOptions.find((option) => option.id === newDocument.clientModuleId),
    [classesOptions, newDocument.clientModuleId],
  );

  const isValid = useMemo(() => {
    return (
      (newDocument?.subtitle?.length || newDocument.predefinedTitle?.length) &&
      !!version &&
      version !== '0.0' &&
      isNumberValid &&
      !!newDocument.clientModuleId &&
      !!newDocument.clientId &&
      (!!newDocument.fileId || !!newDocument.templateFormId)
    );
  }, [
    isNumberValid,
    newDocument.clientId,
    newDocument.clientModuleId,
    newDocument.fileId,
    newDocument.predefinedTitle?.length,
    newDocument?.subtitle?.length,
    newDocument.templateFormId,
    version,
  ]);

  const validateDocNumber = useCallback((clientModuleId: string, docNumber: number) => {
    setIsValidating(true);
    DocumentService.validateNumber(clientModuleId, docNumber)
      .then((res) => {
        setIsNumberValid(!res.data.inUse);
      })
      .finally(() => {
        setIsValidating(false);
      });
  }, []);

  useEffect(() => {
    newDocument.clientModuleId && validateDocNumber(newDocument.clientModuleId, parseInt(number) || 0);
  }, [newDocument.clientModuleId, number, validateDocNumber]);

  const onSelectedClassChange = useCallback(
    (value: Item) => {
      const selectedClass = classes.find((x) => x.clientModuleId === value.id);
      const defaultConfig = selectedClass?.defaults?.configuration || {
        accessType: Access.restricted,
        users: {},
      };
      if (currentUser && currentUser.id) {
        const defaultUsers = Object.values(defaultConfig.users);
        if (!defaultUsers.length || !defaultUsers.some((value) => value === ClientFormUserRole.Owner)) {
          defaultConfig.users[currentUser?.id] = ClientFormUserRole.Owner;
        }
      }

      const newNumber = selectedClass?.nextDocumentNumber ?? 1;
      setNumber(newNumber.toString());
      setNewDocument &&
        setNewDocument((prev) => ({
          ...prev,
          clientModuleId: value.id,
          accessConfiguration: defaultConfig,
          number: newNumber,
        }));
      validateDocNumber(value.id, newNumber);
    },
    [classes, currentUser, setNewDocument, validateDocNumber],
  );

  const onVersionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const regex = /^$|^\d+(\.\d{0,3})?$/;
      if (regex.test(inputValue)) {
        setVersion(inputValue);
        const versionParts = inputValue.split('.');
        const majorVersion = parseInt(versionParts[0]) || 0;
        const minorVersion = parseInt(versionParts[1]) || 0;
        setNewDocument && setNewDocument((prev) => ({ ...prev, majorVersion: majorVersion, minorVersion: minorVersion }));
      }
    },
    [setNewDocument],
  );

  const onDocNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const regex = /^\d*$/;
      if (regex.test(inputValue)) {
        setNumber(inputValue);
        const docNumber = parseInt(inputValue) || 0;
        setNewDocument && setNewDocument((prev) => ({ ...prev, number: docNumber }));
        newDocument.clientModuleId && validateDocNumber(newDocument.clientModuleId, docNumber);
      }
    },
    [newDocument.clientModuleId, setNewDocument, validateDocNumber],
  );

  return (
    <StandardModal
      title={t('create-doc-wizard.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={prevStep}
      confirmButtonTitle={t('manage-wizard.buttons.next')}
      confirmDisabled={!isValid || isValidating}
      onConfirmClick={() => nextStep && nextStep('access')}
      tertiaryButtonIcon={null}
    >
      {stepNames && nextStep && (
        <WizardStepsIndicator activeStepIndex={0} stepNames={stepNames.filter((x) => x === 'detailStep' || x === 'access')} onStepChange={nextStep} />
      )}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        {fromFile && newDocument.file && <File file={newDocument.file} onRemove={deleteFile} />}
        <div className="text-dpm-16 mt-8 font-medium">{t('manage-wizard.details')}</div>
        {!newDocument.predefinedTitle && (
          <Input
            placeholder={t('create-doc-wizard.detail.doc-name')}
            label={t('create-doc-wizard.detail.doc-name')}
            value={newDocument.subtitle || ''}
            onChange={(e) => setNewDocument && setNewDocument((prev) => ({ ...prev, subtitle: e.target.value }))}
          />
        )}
        {newDocument.predefinedTitle && (
          <div className="mt-4">
            <label htmlFor="predeftitle" className="text-color-3 text-dpm-12">
              {t('create-doc-wizard.detail.doc-name')}
            </label>
            <div id="predeftitle" className="text-primary-1">
              {newDocument.predefinedTitle}
            </div>
          </div>
        )}
        <div className="mt-4 flex flex-row gap-2">
          {fromFile && (
            <div className="flex-grow">
              <DropdownSelect
                options={classesOptions}
                value={selectedClass}
                onChange={onSelectedClassChange}
                placeholder={t('create-doc-wizard.detail.doc-class')}
                label={t('create-doc-wizard.detail.doc-class')}
              />
            </div>
          )}
          <div className={`${fromFile ? 'w-32' : 'w-1/2'}`}>
            <Input
              placeholder={t('create-doc-wizard.detail.doc-version')}
              label={t('create-doc-wizard.detail.doc-version')}
              value={version}
              onChange={onVersionChange}
              errorState={!version.length || version === '0.0'}
            />
          </div>
          <div className={`${fromFile ? 'w-32' : 'w-1/2'}`}>
            <Input
              placeholder={t('create-doc-wizard.detail.doc-number')}
              label={t('create-doc-wizard.detail.doc-number')}
              value={number}
              onChange={onDocNumberChange}
              errorState={!isNumberValid}
              error={!isNumberValid ? t('create-doc-wizard.detail.doc-number-inuse') : ''}
            >
              <Input.Slot name="trailing">{isValidating && <Loader size={6} centered={false} />}</Input.Slot>
            </Input>
          </div>
        </div>
        <div className="mt-4">
          <MultiTextField
            placeholder={t('create-doc-wizard.detail.doc-description')}
            label={t('create-doc-wizard.detail.doc-description')}
            onChange={(e) => setNewDocument && setNewDocument((prev) => ({ ...prev, description: e.target.value }))}
          />
        </div>

        <div className="text-primary-1 mt-4">
          {t('create-doc-wizard.detail.doc-due-date')}
          <DueDatePicker
            clientFormStatus={ClientFormStatus.NotStarted}
            disabled={false}
            value={newDocument.dueDateUtc ? new Date(newDocument.dueDateUtc) : null}
            onChange={(value) => setNewDocument && setNewDocument((prev) => prev && { ...prev, dueDateUtc: value })}
          />
        </div>

        <div className="mt-4">
          <EffectiveDateModalContent
            heading={true}
            value={newDocument.effectiveDateUtc ? new Date(newDocument.effectiveDateUtc) : null}
            setValue={(value) => setNewDocument && setNewDocument((prev) => prev && { ...prev, effectiveDateUtc: value })}
          />
        </div>
      </div>
    </StandardModal>
  );
};

export default DetailStep;
